import React, { useState, useEffect, Fragment } from "react";
import Input from "../../Components/Input";
import Button from "../../Components/Button";
import { filter } from "../../utils/filter";
import { debounce } from "../../utils/debounce";
import PluginCard from "../../Components/PluginCard";
import useFetchAddons from "../../hooks/useFetchAddons";
import PluginCardSkeleton from "../../Components/PluginCardSkeleton";
import MetaDocument from "../../MetaDocument/MetaDocument";

const productTypes = [
	{ id: 1, value: "all", label: "All", count: 0 },
	{ id: 2, value: "premium", label: "Premium", count: 0 },
	{ id: 3, value: "free", label: "Free", count: 0 },
];

const Plugins = () => {
	const { data, isLoading } = useFetchAddons();
	const [categories, setCategories] = useState(productTypes);
	const [addons, setAddons] = useState([]);
	const [filteredAddons, setFilteredAddons] = useState([]);
	const [selectedType, setSelectedType] = useState("all");
	const [searchKeyword, setSearchKeyword] = useState("");

	const handleSelectType = (type) => {
		setSelectedType(type);
	};

	const handleSearch = debounce((e) => {
		setSearchKeyword(e.target.value);
	});

	useEffect(() => {
		if (data?.length) {
			setAddons(data);
		}
	}, [data]);

	// set categories count based on addons
	useEffect(() => {
		if (addons?.length) {
			const all = addons?.length;
			const premium = addons?.filter(
				(addon) => Number(addon?.price_cents) > 0
			)?.length;
			const free = addons?.filter(
				(addon) => Number(addon?.price_cents) === 0
			)?.length;
			setCategories((prev) => {
				return prev.map((cat) => {
					if (cat?.value === "all") {
						return { ...cat, count: all };
					} else if (cat?.value === "premium") {
						return { ...cat, count: premium };
					} else if (cat?.value === "free") {
						return { ...cat, count: free };
					}
				});
			});
		}
	}, [addons]);

	// addon search by name, tags and type
	useEffect(() => {
		if (addons?.length) {
			setFilteredAddons(filter(searchKeyword, addons, selectedType));
		}
	}, [searchKeyword, addons, selectedType]);

	if (isLoading) {
		return (
			<div className="bg-gray_4 pt-5 pb-16 sm:pb-24">
				<div className="container mx-auto max-w-7xl px-5 3xl:px-0">
					<div className="mt-10 mb-8 flex items-center justify-between flex-wrap gap-5 border-b border-b-gray/30 pb-3">
						<div className="flex items-center gap-5">
							{[...Array(3)].map((e, i) => (
								<div
									key={i}
									className="h-[33px] w-[70px] animate-pulse rounded-sm bg-[#c1c1c3]"
								></div>
							))}
						</div>
						{/* filter by search keyword */}
						<div className="h-[33px] max-w-xs w-full animate-pulse rounded-sm bg-[#c1c1c3]"></div>
					</div>
					<div className="grid gap-8 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
						{[...Array(6)].map((e, i) => (
							<PluginCardSkeleton key={i} />
						))}
					</div>
				</div>
			</div>
		);
	}

	if (!isLoading && !addons?.length) {
		return (
			<div className="w-full h-[calc(100vh-64px)] px-5 md:h-[calc(100vh-92px)] bg-gray_4 flex flex-col items-center justify-center">
				<h1 className="roboto text-light_black text-center font-bold text-2xl sm:text-3xl">
					No Plugins Found!
				</h1>
				<p className="text-light_black text-base sm:text-lg text-center">
					Oops! It looks like we couldn't find the plugin you were looking for.
				</p>
			</div>
		);
	}

	return (
		<Fragment>
			{/* meta tags */}
			<MetaDocument
				title="Martvill - A collection of plugins to enhance your website functionality"
				description="Martvill - This is a collection of plugins to enhance your website functionality. We have both free and premium plugins. You can find the plugin you need by searching or filtering by type. We have a variety of plugins like payment gateways, shipping methods, and many more."
				pageUrl="https://martvill.techvill.net/plugins"
				image={"/plugin-thumbnail.png"}
				siteName="Martvill"
			/>

			<div className="min-h-[calc(100vh-150px)] bg-gray_4 pt-5 pb-16 sm:pb-24">
				<div className="container mx-auto max-w-7xl px-5 3xl:px-0">
					{/* filter by click */}
					<div className="mt-10 mb-8 flex items-center justify-between flex-wrap gap-5 border-b border-b-gray/30 pb-3">
						<div className="flex items-center flex-wrap gap-5">
							{categories?.map((type) => (
								<Button
									key={type?.id}
									selected={selectedType === type?.value}
									onClick={() => handleSelectType(type?.value)}
								>
									{type?.label}
									<span className="ml-1">({type.count})</span>
								</Button>
							))}
						</div>
						{/* filter by search keyword */}
						<Input onChange={handleSearch} />
					</div>
					{/* plugins */}
					{filteredAddons?.length > 0 ? (
						<div className="grid gap-8 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
							{filteredAddons?.map((addon) => {
								return (
									<div
										key={addon?.id}
										className="features-col group box_shadow_Bfe w-full"
									>
										<PluginCard addon={addon} />
									</div>
								);
							})}
						</div>
					) : (
						<div className="mt-[100px] w-full px-5 bg-gray_4 flex flex-col items-center justify-center">
							<h1 className="roboto text-light_black text-center font-bold text-2xl sm:text-3xl">
								No Plugins Found!
							</h1>
							<p className="text-light_black text-base sm:text-lg text-center">
								Oops! It looks like we couldn't find the plugin you were looking
								for.
							</p>
						</div>
					)}
				</div>
			</div>
		</Fragment>
	);
};

export default Plugins;
