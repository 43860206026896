import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import CartIcon from "../../Components/CartIcon";
import LinkIcon from "../../Components/LinkIcon";
import CheckIcon from "../../Components/CheckIcon";
import { formatDate } from "../../utils/format-date";
import TwiceContent from "../../Components/TwiceContent";
import ViewFinderIcon from "../../Components/ViewFinderIcon";
import NotFoundPlugin from "../../Components/NotFoundPlugin";
import { checkUpdateStatus } from "../../utils/update-time-watcher";
import PluginDetailsSkeleton from "../../Components/PluginDetailsSkeleton";
import DownloadIcon from "../../Components/DownloadIcon";
import MetaDocument from "../../MetaDocument/MetaDocument";

const PluginDetails = () => {
	let { pluginId } = useParams();
	const { pathname } = useLocation();
	const [addons, setAddons] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const plugin = addons.find((addon) => addon.id == pluginId);
	const demoSiteUrl = plugin?.attributes.find(
		(attr) => attr?.name === "demo-url"
	);

	// fetch addons
	useEffect(() => {
		if (pluginId) {
			const fetchAddons = async () => {
				try {
					const res = await fetch(
						"https://support.techvill.org/api/v1/martvill/addons"
					);
					const data = await res.json();
					setAddons(data?.response?.records);
					setIsLoading(false);
				} catch (error) {
					setIsLoading(false);
				}
			};
			fetchAddons();
		}
	}, [pluginId]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	if (isLoading) {
		return <PluginDetailsSkeleton />;
	}

	if (!isLoading && !plugin) {
		return <NotFoundPlugin />;
	}

	return (
		<Fragment>
			{/* meta tags */}
			<MetaDocument
				title={plugin?.name}
				description=""
				pageUrl="https://martvill.techvill.net/"
				image={plugin?.previews.landscape_preview.landscape_url}
				siteName="Martvill"
			/>

			<div className="py-10 sm:py-16 container mx-auto max-w-7xl px-5 3xl:px-0">
				<h1 className="roboto text-light_black font-bold text-2xl sm:text-3xl">
					{plugin?.name}
				</h1>
				<div className="mt-2 flex gap-5">
					<div className="flex item-center gap-1.5">
						{plugin?.price_cents != 0 ? <CartIcon /> : <DownloadIcon />}
						<p className="break-all roboto font-semibold text-gray">
							{plugin?.number_of_sales}{" "}
							<span className="font-normal text-sm">
								{plugin?.price_cents != 0 ? "Sales" : "Downloads"}
							</span>
						</p>
					</div>
					<div className="flex item-center gap-1.5">
						<p className="break-all roboto font-semibold text-dark_yellow">
							{checkUpdateStatus(plugin?.updated_at)}
						</p>
						<CheckIcon />
					</div>
				</div>

				<section className="mt-5 grid gap-10 grid-cols-1 xl:grid-cols-3">
					<div className="xl:col-span-2">
						<div className="bg-white p-4 rounded-md border border-[#e1e8ed]">
							<div className="relative main-item transition-all">
								<a
									className="child-item absolute top-0 left-0 w-full h-full invisible"
									href={demoSiteUrl?.value}
									target="_blank"
									rel="noopener noreferrer"
								>
									<div className="absolute bg-black bg-opacity-75 w-full h-full flex items-center justify-center flex-col">
										<ViewFinderIcon />
										<div className="mt-3 flex items-center gap-2">
											<p className="text-white roboto text-sm font-normal">
												Preview Item
											</p>
											<LinkIcon />
										</div>
									</div>
								</a>
								<div className="w-full">
									<img
										className="w-full h-full"
										src={plugin?.previews.landscape_preview.landscape_url}
										alt="plugin landscape preview"
									/>
								</div>
							</div>
							{demoSiteUrl?.value && (
								<div className="mt-5 flex items-center gap-3">
									<a
										className="w-full flex items-center justify-center p-1 sm:p-1.5 text-sm text-center font-semibold bg-light_black hover:bg-yellow text-white hover:text-light_black rounded-sm transition-all"
										href={demoSiteUrl?.value}
										target="_blank"
										rel="noopener noreferrer"
									>
										Live Preview
									</a>
								</div>
							)}
						</div>
						<div className="my-5">
							<div
								dangerouslySetInnerHTML={{
									__html: plugin?.description,
								}}
							/>
						</div>
					</div>
					<aside>
						{/* Select License */}
						<div className="shadow rounded-md overflow-hidden bg-[#f4f5f7]">
							{/* top section */}
							{plugin?.price_cents != 0 ? (
								<>
									<div className="bg-yellow p-3.5">
										<h5 className="roboto text-light_black font-bold text-xl break-all">
											Select License
										</h5>
										<p className="text-light_black break-all">
											Choose Suitable License
										</p>
									</div>
									<div className="flex gap-3 items-center justify-between p-3.5">
										<CheckIcon />
										<div>
											<h6 className="text-lg font-semibold text-light_black">
												Regular License
											</h6>
											<p className="text-gray">
												Use or by you or only client in a single we end.
											</p>
										</div>
										<h4 className="text-3xl font-semibold text-light_black">
											${plugin?.price_cents / 100}
										</h4>
									</div>

									<div className="m-5">
										<a
											href={`https://codecanyon.net/cart/configure_before_adding/${plugin?.id}`}
											target="_blank"
											rel="noopener noreferrer"
										>
											<button className="rounded w-full py-3 bg-light_black hover:bg-yellow transition-all text-white hover:text-light_black font-semibold text-base sm:text-lg">
												Purchase Now
											</button>
										</a>
									</div>
								</>
							) : (
								<a
									href={`https://support.techvill.org/api/v1/addon/download/${plugin?.id}`}
									rel="noopener noreferrer"
								>
									<button className="rounded w-full py-3 bg-light_black hover:bg-yellow transition-all text-white hover:text-light_black font-semibold text-base sm:text-lg">
										Download Now
									</button>
								</a>
							)}
						</div>

						{/* Product Information */}
						<div className="shadow mt-6 rounded-md overflow-hidden bg-[#f4f5f7]">
							{/* top section */}
							<div className="bg-gray p-3.5">
								<h5 className="roboto text-white font-bold text-xl break-all">
									Product Information
								</h5>
							</div>
							<div className="p-3.5">
								<TwiceContent
									label="Published"
									value={formatDate(plugin?.published_at)}
								/>
								<TwiceContent
									label="Updated at"
									value={formatDate(plugin?.updated_at)}
								/>
								{plugin?.attributes.map((attr, idx) => {
									return (
										<TwiceContent
											key={idx}
											label={attr?.label}
											value={attr?.value}
										/>
									);
								})}
							</div>
						</div>
					</aside>
				</section>
			</div>
		</Fragment>
	);
};

export default PluginDetails;
