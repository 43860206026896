import React from "react";
import NotFoundImg from "../assets/img/4o4.svg";

const NotFound = () => {
	return (
		<div className="w-full h-[calc(100vh-64px)] px-5 md:h-[calc(100vh-92px)] bg-gray_4 flex flex-col items-center justify-center">
			<div className="my-4">
				<p className="stroke-text text-6xl font-bold text-yellow">
					404
				</p>
				<p className="bg-light_black text-white text-center uppercase font-semibold">
					Error
				</p>
			</div>
			<img src={NotFoundImg} alt="not-found" />
			<p className="my-4 text-center text-xl font-medium">
				The page you are looking for is not available at the moment.
			</p>
		</div>
	);
};

export default NotFound;
