import React from "react";
const TwiceContent = ({ label, value }) => {
	return (
		<div className="my-3.5 w-full flex items-start">
			<div className="w-1/2 mr-5">
				<p className="text-gray">{label}</p>{" "}
			</div>
			<div className="w-full">
				{typeof value === "string" ? (
					value?.includes("http") ? (
						<a
							className="text-[#1f7ec2] break-all hover:underline"
							href={value}
							target="_blank"
							rel="noopener noreferrer"
						>
							{value}
						</a>
					) : (
						<p className="text-gray break-all">{value}</p>
					)
				) : (
					<div className="flex gap-1 flex-wrap">
						{value?.map((val, idx) => (
							<p className="text-[#1f7ec2] break-all" key={idx}>
								{val}
								{idx < value.length - 1 ? ", " : ""}
							</p>
						))}
					</div>
				)}
			</div>
		</div>
	);
};

export default TwiceContent;
