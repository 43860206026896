import React from "react";

const PluginCardSkeleton = () => {
	return (
		<div className="max-w-sm flex flex-col bg-white rounded-md overflow-hidden h-full border border-[#e1e8ed]/80">
			{/* card image */}
			<div className="h-[200px] animate-pulse bg-[#c1c1c3]"></div>
			{/* card body */}
			<div className="px-5 pt-4">
				<h6 className="animate-pulse bg-[#c1c1c3] h-4 rounded-xl"></h6>
				<h6 className="animate-pulse bg-[#c1c1c3] h-2 w-1/2 mt-2 rounded-xl"></h6>
				<h6 className="mt-4 animate-pulse bg-[#c1c1c3] h-4 w-16 rounded-xl"></h6>
			</div>
			{/*card footer */}
			<div className="mt-auto px-5 pb-4 flex gap-2 justify-between items-center">
				<h6 className="mt-3 animate-pulse bg-[#c1c1c3] h-4 w-16 rounded-xl"></h6>
				{/* card actions */}
				<div className="flex gap-2">
					<div className="animate-pulse bg-[#c1c1c3] h-8 w-16 rounded"></div>
					<div className="animate-pulse bg-[#c1c1c3] h-8 w-16 rounded"></div>
				</div>
			</div>
		</div>
	);
};

export default PluginCardSkeleton;
