import React from "react";
import codecanyon from "../../assets/logo/codecanyon.svg";
import envato_1 from "../../assets/logo/envato_1.svg";
import envato_2 from "../../assets/logo/envato_2.svg";
import footer_logo from "../../assets/logo/footer-logo.svg";
const Availability = () => {
  return (
    <div className=" max-w-[413px]">
      <div>
        <a href="#">
          <img
            src={footer_logo}
            alt=""
            className="w-[160px] lg:w-[50%] 2xl:w-auto"
          />
        </a>
      </div>
      <p className=" text-xs mt-6 lg:mt-[30px] mb-[22px]  lg:mb-[34px] text-[#E2E2E2]  lg:text-xs 4xl:text-[15px] leading-5 lg:leading-[25px]">
        The ultimate e-commerce solution currently in the market to get your
        business running at the speed of light.
      </p>
      <div className="flex gap-5 pt-2">
        <div>
          <p className="pb-1 !text-white text-[12px]">Available on</p>
          <a
            href="https://codecanyon.net/item/martvill-a-global-multivendor-ecommerce-platform-to-sell-anything/43288879"
            target="_blank"
            rel="noopener"
          >
            <img
              src={codecanyon}
              alt=""
              className="w-[116px] lg:w-[80%] 2xl:w-auto"
            />
          </a>
        </div>
        <div className="w-px h-100 bg-gray"></div>
        <div>
          <p className="pb-1 !text-white text-[12px]">Selling on</p>
          <a
            href="https://codecanyon.net/item/martvill-a-global-multivendor-ecommerce-platform-to-sell-anything/43288879"
            target="_blank"
            rel="noopener"
          >
            <div className="flex space-x-1 w-[88px] lg:w-[80%] 2xl:w-auto">
              <img src={envato_1} alt="" />
              <img src={envato_2} alt="" />
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Availability;
