import React from "react";
import { Link } from "react-router-dom";

const NotFoundPlugin = () => {
	return (
		<div className="w-full h-[calc(100vh-64px)] px-5 md:h-[calc(100vh-92px)] bg-gray_4 flex flex-col items-center justify-center">
			<h1 className="roboto text-light_black text-center font-bold text-2xl sm:text-3xl">
				No Plugin Found!
			</h1>
			<p className="text-light_black text-base sm:text-lg text-center">
				Oops! It looks like we couldn't find the plugin you were looking
				for.
			</p>
			{/* button to back */}
			<Link to="/plugins">
				<button className="mb-3 mt-8 flex gap-1 items-center rounded w-full px-5 py-2 bg-light_black hover:bg-yellow transition-all text-white hover:text-light_black font-semibold text-base sm:text-lg">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						strokeWidth="1.5"
						stroke="currentColor"
						className="w-5 h-5"
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
						/>
					</svg>

					<span>Browse plugins</span>
				</button>
			</Link>
		</div>
	);
};

export default NotFoundPlugin;
