import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../Components/Header";
import Footer from "../Components/Footer/Footer";
import MetaDocument from "../MetaDocument/MetaDocument";

const Layout = () => {
	return (
		<>
			{/* common meta tags */}
			<MetaDocument
				title="Martvill - A global multi vendor e-commerce platform to Sell Anything"
				description="An all-in-one platform for vendors to sell products globally with ease"
				pageUrl="https://martvill.techvill.net/"
				image="/thumbnail.png"
				siteName="Martvill"
			/>

			<Header />
			<Outlet />
			<Footer />
		</>
	);
};

export default Layout;
