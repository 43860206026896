export const filter = (searchKeyword, addons, selectedType) => {
	if (searchKeyword) {
		return addons?.filter((addon) => {
			const name = addon?.name
				?.toLowerCase()
				?.includes(searchKeyword?.toLowerCase());
			const category = addon?.tags?.some((cat) =>
				cat?.toLowerCase()?.includes(searchKeyword?.toLowerCase())
			);
			const type =
				selectedType === "all"
					? true
					: selectedType === "premium"
					? Number(addon?.price_cents) > 0
					: Number(addon?.price_cents) === 0;
			return (name || category) && type;
		});
	} else {
		if (selectedType === "all") {
			return addons;
		} else if (selectedType === "premium") {
			return addons?.filter((addon) => Number(addon?.price_cents) > 0);
		} else if (selectedType === "free") {
			return addons?.filter((addon) => Number(addon?.price_cents) === 0);
		}
	}
};
