import React from "react";
import techVillage from "../../assets/logo/techvillage.svg";
const Products = () => {
  return (
    <div className="max-w-[413px]">
      <div className="mb-[50px] lg:mb-[43px]">
        <p className="mb-[18px] lg:mb-4 !text-white text-xs 4xl:text-sm">
          A PRODUCT OF
        </p>
        <a href="/">
          <img
            src={techVillage}
            alt=""
            className="w-[120px] lg:w-[40%] 2xl:w-auto"
          />
        </a>
      </div>
      <div>
        <h2 className="font-bold !text-white text-[15px]  leading-6 2xl:text-base">
          OUR OTHER PRODUCTS
        </h2>
        <ul className="space-y-[13px]  text-[#B6B6B6] mt-[17px] lg:mt-7  font-medium">
          <li>
            <a
              href="https://codecanyon.net/item/paymoney-secure-online-payment-gateway/22341650?s_rank=2"
              target="blank"
              className=" hover:!text-yellow transition-all cursor-pointer text-[14px] leading-[22px]"
            >
              PayMoney - Secure Online Payment Gateway
            </a>
          </li>
          <li>
            <a
              href="https://codecanyon.net/item/vrent-vacation-rental-marketplace/19418596?s_rank=5"
              target="blank"
              className=" hover:!text-yellow transition-all text-[13px] 2xl:text-sm"
            >
              vRent - Vacational Rental Market Place
            </a>
          </li>
          <li>
            <a
              href="https://codecanyon.net/item/rovercrm-customer-relationship-and-project-management-system/27995551"
              target="blank"
              className=" hover:!text-yellow transition-all text-[13px] 2xl:text-sm"
            >
              RoverCRM - Customer Relationship And Project Management System
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Products;
