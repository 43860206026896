import React from "react";

const CopyRightText = ({ link }) => {
  return (
    <>
      <p className="text-[10px] leading-[13px] lg:text-base 4xl:text-base !text-white ">
        Copyright © 2022{" "}
        <a href={"https://www." + link} target="_blank" className="text-yellow">
          {link}
        </a>{" "}
        | All Rights Reserved.
      </p>
    </>
  );
};

export default CopyRightText;
