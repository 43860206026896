import React from "react";

import call from "../../assets/icon/call.svg";
import email from "../../assets/icon/email.svg";
import location from "../../assets/icon/location.svg";
const GetInTouch = () => {
    return (
        <div className='max-w-[413px]'>
            <h2 className='font-bold !text-white text-base'>GET IN TOUCH</h2>
            <div className='space-y-5 lg:space-y-[30px]  text-[#B6B6B6] mt-5 lg:mt-8 text-[13px] 2xl:text-[15px] leading-6'>
                <p className='flex space-x-2 items-center'>
                    <img src={call} alt='Call Logo' />
                    <span className='!text-white'>
                        <a href='tel:+880-1717-170543 '>+880-1717-170543</a>
                    </span>
                </p>
                <p className='flex space-x-2 items-center text-[13px] 2xl:text-[15px] leading-6'>
                    <span>
                        <img src={email} alt='Email Logo' />
                    </span>
                    <span className='!text-white'>
                        <a href='mailto:support@techvill.org'>
                            support@techvill.org
                        </a>
                    </span>
                </p>
                <p className='flex space-x-2 items-start text-[13px] 2xl:text-[15px] leading-6'>
                    <span>
                        <img src={location} alt='Location Logo' />
                    </span>
                    <span className='!text-white leading-[25px] '>
                        House-19, Road-19, Nikunja-2,
                        <br /> Dhaka 1229, Bangladesh.
                    </span>
                </p>
            </div>
        </div>
    );
};

export default GetInTouch;
